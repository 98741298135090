import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  block: any;
  inner: any;
  innerContent: any;
  noInnerContentPadding: any;
  closeButton: any;
  closeButtonIcon: any;
}

export const enterDuration = 400;
export const exitDuration = 400;

export const ModalDialogWithCloseStyle: IStyle = StyleSheet.create({
  block: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(40,40,40,0.2)",
    width: "100%",
    height: "100%",
    zIndex: 1000,
  },
  inner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    height: "100%",
    maxHeight: "80%",
    maxWidth: "540px",
    background: "#fff",
    borderRadius: borderRadius.normal,
    boxShadow: "0 6px 30px rgba(40,40,40,0.1)",
    boxSizing: "border-box",
    willChange: "transform",
  },
  innerContent: {
    height: "calc(100% - 3rem)",
    marginTop: "3rem",
    overflowY: "auto",
    // "-webkitOverflowScrolling": "touch",
    padding: `0 ${space.atom2x} 2rem`,
    boxSizing: "border-box",
    [moreThanBreakpoint("tablet")]: {
      padding: "0 3rem 2rem",
    },
  },
  noInnerContentPadding: {
    padding: 0,
    [moreThanBreakpoint("tablet")]: {
      padding: 0,
    },
  },
  closeButton: {
    width: "3rem",
    height: "3rem",
    textAlign: "center",
    boxSizing: "border-box",
    fontSize: "24px",
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
    opacity: 0.5,
    transition: "all 0.3s ease",
    backfaceVisibility: "hidden",
    zIndex: 100,
    ":hover": {
      opacity: 1,
    },
  },
  closeButtonIcon: {
    lineHeight: "3rem",
  },
});

export const defaultBlockStyle: any = {
  transition: `all ${enterDuration}ms ease`,
  opacity: 0,
};

export const transitionBlockStyles: any = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    transition: `all ${exitDuration}ms ease`,
  },
  exited: {
    opacity: 0,
    display: "none",
  },
};

export const defaultInnerStyle: any = {
  transition: `all ${enterDuration}ms ease`,
  transform: "translate(-50%, -50%) scale(0.9)",
};

export const transitionInnerStyles: any = {
  entering: {
    transform: "translate(-50%, -50%) scale(0.9)",
  },
  entered: {
    transform: "translate(-50%, -50%) scale(1)",
  },
  exiting: {
    transition: `all ${exitDuration}ms ease`,
  },
  exited: {
    transform: "translate(-50%, -50%) scale(0.9)",
    display: "none",
  },
};
