import { StyleSheet } from "aphrodite";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { minResolution } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  button: any;
  buttonSmall: any;
  buttonSecondary: any;
  buttonSubmit: any;
  block: any;
  loaderBlock: any;
  buttonWithLoader: any;
  text: any;
  textForLoading: any;
}

export const ButtonStyle: IStyle = StyleSheet.create({
  button: {
    position: "relative",
    fontSize: fontSize.mediumHeading,
    fontWeight: fontWeight.regular,
    color: "white",
    backgroundColor: accentColor.primaryColor,
    padding: `0 ${space.atom}`,
    border: "none",
    borderRadius: borderRadius.normal,
    margin: 0,
    width: "100%",
    minWidth: "64px",
    height: "40px",
    lineHeight: "40px",
    boxSizing: "border-box",
    overflow: "hidden",
    textAlign: "center",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: "0.1em",
    transition: "background 0.4s ease, opacity 0.4s ease",
    ":hover": {
      backgroundColor: accentColor.primaryDarkColor,
    },
    ":disabled": {
      opacity: 0.75,
    },
    [minResolution("scale2x")]: {
      fontWeight: fontWeight.bold,
    },
  },
  buttonSmall: {
    fontSize: fontSize.subHeading,
    fontWeight: fontWeight.regular,
    color: "white",
    backgroundColor: accentColor.primaryColor,
    padding: `0 ${space.atom0_5x}`,
    border: "none",
    borderRadius: borderRadius.normal,
    margin: 0,
    width: "100%",
    minWidth: "64px",
    height: "40px",
    lineHeight: "40px",
    boxSizing: "border-box",
    overflow: "hidden",
    textAlign: "center",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: "0.1em",
    transition: "background 0.4s ease, opacity 0.4s ease",
    ":hover": {
      backgroundColor: accentColor.primaryDarkColor,
    },
    ":disabled": {
      opacity: 0.5,
    },
    [minResolution("scale2x")]: {
      fontWeight: fontWeight.bold,
    },
  },
  buttonSecondary: {
    position: "relative",
    fontSize: fontSize.mediumHeading,
    fontWeight: fontWeight.regular,
    color: accentColor.primaryColor,
    backgroundColor: "#fff",
    padding: `0 ${space.atom}`,
    border: `1px solid ${accentColor.primaryColor}`,
    borderRadius: borderRadius.normal,
    margin: 0,
    width: "100%",
    minWidth: "64px",
    height: "40px",
    lineHeight: "38px",
    boxSizing: "border-box",
    overflow: "hidden",
    textAlign: "center",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: "0.1em",
    display: "inline-block",
  },
  buttonSubmit: {
    position: "relative",
    fontSize: fontSize.mediumHeading,
    fontWeight: fontWeight.regular,
    color: "white",
    backgroundColor: accentColor.primaryColor,
    padding: `0 ${space.atom}`,
    border: "none",
    borderRadius: borderRadius.normal,
    margin: 0,
    width: "100%",
    minWidth: "64px",
    height: "54px",
    lineHeight: "54px",
    boxSizing: "border-box",
    overflow: "hidden",
    textAlign: "center",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: "0.1em",
    transition: "background 0.4s ease, opacity 0.4s ease",
    ":before": {
      content: "''",
      border: "1px solid #fff",
      display: "block",
      borderRadius: "3px",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: `calc(100% - ${space.atom})`,
      height: `calc(100% - ${space.atom})`,
      transform: "translate(-50%, -50%)",
      boxSizing: "border-box",
    },
    ":hover": {
      backgroundColor: accentColor.primaryDarkColor,
    },
    ":disabled": {
      opacity: 0.5,
    },
    [minResolution("scale2x")]: {
      fontWeight: fontWeight.bold,
    },
  },
  block: {
    padding: space.atom,
  },
  loaderBlock: {
    left: "auto",
    right: "0.5rem",
    transform: "translate(0, -50%)",
  },
  buttonWithLoader: {
    padding: "0 1.2rem",
    position: "relative",
    minWidth: "80px",
    ":disabled": {
      opacity: 0.75,
    },
  },
  text: {
    display: "inline-block",
    transition: "transform 0.4s ease",
  },
  textForLoading: {
    transform: "translateX(-1rem)",
  },
});
