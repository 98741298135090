import * as React from "react";
import { css } from "aphrodite";
import { format } from "date-fns";
import isEqual from "react-fast-compare";
import { CalendarStyle } from "./CalendarStyle";
import ja from "date-fns/locale/ja";
import { DayNames } from "./DayNames";
import { Days } from "./Days";

interface IProps {
  date: Date;
  startDate: Date | null;
  endDate: Date | null;
  onSelect(date: Date): void;
  onMouseOver?(date: Date): void;
  onMouseOut?(): void;
  showDayNames?: boolean;
  disableDate?: any;
  styles?: any;
  innerRef?: any;
}

class CalendarComponent extends React.Component<IProps> {
  public shouldComponentUpdate(nextProps: any): boolean {
    return !isEqual(this.props, nextProps);
  }
  public render(): JSX.Element {
    const {
      date,
      startDate,
      endDate,
      onSelect,
      onMouseOver,
      onMouseOut,
      disableDate,
      showDayNames,
      styles = {},
    } = this.props;
    const { calendarStyle, dayNamesStyle } = styles;
    return (
      <div
        className={css(CalendarStyle.calendar, calendarStyle)}
        ref={this.props.innerRef}
      >
        <div className={css(CalendarStyle.calendarHeader)}>
          {format(date, "LLL", { locale: ja })} {format(date, "yyyy")}
        </div>
        {showDayNames && (
          <div className={css(CalendarStyle.dayNames, dayNamesStyle)}>
            <DayNames date={date} />
          </div>
        )}
        <Days
          onClick={onSelect}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          date={date}
          startDate={startDate}
          endDate={endDate}
          disableDate={disableDate}
        />
      </div>
    );
  }
}
export const Calendar: any = React.forwardRef((props: IProps, ref: any) => (
  <CalendarComponent innerRef={ref} {...props} />
));
