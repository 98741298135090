import * as React from "react";
import { format, isSameDay, startOfWeek } from "date-fns";
import ja from "date-fns/locale/ja";
import { DayLabel } from "./DayLabel";

interface IProps {
  date: Date;
}

const DayNamesComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { date } = props;
  const thisDate: Date = date;
  const dayOfWeek: Date = startOfWeek(thisDate);
  const labels: any = [];

  for (let i = 1; i <= 7; i += 1) {
    labels.push(
      <DayLabel key={`calendarLabel${i}`}>
        {format(dayOfWeek, "iiiiii", { locale: ja })}
      </DayLabel>
    );
    dayOfWeek.setDate(dayOfWeek.getDate() + 1);
  }

  return <div>{labels}</div>;
};
const areEqual: any = (prevProps: any, nextProps: any): any => {
  return isSameDay(prevProps.date, nextProps.date);
};
export const DayNames: any = React.memo(DayNamesComponent, areEqual);
