import { StyleSheet } from "aphrodite";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  inner: any;
  innerContent: any;
  closeButton: any;
  close: any;
  innerLight: any;
  closeLight: any;
}

export const FullScreenModalDialogStyle: IStyle = StyleSheet.create({
  inner: {
    backgroundColor: "#232323",
    width: "calc(100% + 1px)",
    height: "calc(100% + 1px)",
    maxWidth: "calc(100% + 1px)",
    maxHeight: "calc(100% + 1px)",
    boxShadow: "none",
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  innerContent: {
    padding: 0,
    overflow: "hidden",
    [moreThanBreakpoint("tablet")]: {
      padding: 0,
    },
  },
  closeButton: {
    opacity: 1,
    ":hover": {
      opacity: 0.7,
    },
  },
  close: {
    color: "#fff",
  },
  innerLight: {
    backgroundColor: "#fff",
    width: "calc(100% + 1px)",
    height: "calc(100% + 1px)",
    maxWidth: "calc(100% + 1px)",
    maxHeight: "calc(100% + 1px)",
    boxShadow: "none",
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  closeLight: {
    color: "#232323",
  },
});
