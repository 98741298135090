import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

export interface IStyle {
  block: any;
  close: any;
  dayNames: any;
  calendarBlock: any;
  calendar: any;
  dateInfo: any;
  dateInfoAlert: any;
  footer: any;
  horizontalFooter: any;
  button: any;
  entering: any;
  entered: any;
  exiting: any;
}

export const enterDuration = 360;
export const exitDelay = 100;
export const exitDuration: number = enterDuration + exitDelay;

export const DatePickerStyle: IStyle = StyleSheet.create({
  block: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
    width: "auto",
    backgroundColor: "white",
  },
  close: {
    color: accentColor.primaryColor,
    fontSize: fontSize.mediumHeading,
    position: "absolute",
    top: "17px",
    right: "7.143%",
    transform: "translateX(50%)",
    cursor: "pointer",
    padding: space.atom,
  },
  dayNames: {
    padding: `0 ${space.atom2x}`,
    height: "1.5rem",
    borderBottom: `1px solid ${borderColor.primaryLightColor}`,
    boxSizing: "border-box",
  },
  calendarBlock: {
    height: "calc(100% - 7.6125rem)",
    overflowY: "auto",
    "-webkitOverflowScrolling": "touch",
    position: "relative",
  },
  calendar: {
    padding: `${space.atom2x} ${space.atom2x}`,
    ":first-child": {
      paddingTop: space.atom2x,
    },
  },
  dateInfo: {
    textAlign: "center",
    fontSize: fontSize.body,
    padding: `${space.atom0_5x} ${space.atom}`,
    lineHeight: lineHeight.caption3,
    letterSpacing: "0.05em",
  },
  dateInfoAlert: {
    textAlign: "center",
    fontSize: fontSize.caption,
    padding: `${space.atom0_5x} ${space.atom}`,
    lineHeight: lineHeight.caption3,
    color: accentColor.alertColor,
  },
  footer: {
    padding: space.atom,
    boxSizing: "border-box",
    minHeight: "5.5rem",
    borderTop: `1px solid ${borderColor.primaryLightColor}`,
    boxShadow: "0 0 1.25rem rgba(0,0,0,0.1)",
  },
  horizontalFooter: {
    padding: space.atom,
    boxSizing: "border-box",
    borderTop: `1px solid ${borderColor.primaryLightColor}`,
  },
  button: {
    ":disabled": {
      background: textColor.disabledColor,
    },
  },
  entering: {
    opacity: 0,
    transform: "translateY(-16px)",
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
  },
  exiting: {
    transition: `all ${enterDuration}ms ease 100ms`,
  },
});

export const defaultStyle: any = {
  transition: `all ${enterDuration}ms ease`,
  opacity: 0,
  transform: "translateY(-16px)",
  touchAction: "manipulation",
};

export const transitionStyles: any = {
  entering: {
    opacity: 0,
    transform: "translateY(-16px)",
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
  },
  exiting: {
    transition: `all ${enterDuration}ms ease ${exitDelay}ms`,
  },
  exited: {
    display: "none",
  },
};
