import React, { useEffect } from "react";
import { ModalDialogWithClose } from "main/javascripts/components/atoms/Modal/ModalDialogWithClose";
import { FullScreenModalDialogStyle } from "./FullScreenModalDialogStyle";
import { stopBodyScrolling } from "main/javascripts/utils/ScrollUtil";

export interface IProps {
  displayed: boolean;
  header?: React.ReactNode;
  children: React.ReactNode;
  closeHandler(): void;
  colorMode?: string;
  disableStopBodyScrolling?: boolean;
}

export const FullScreenModalDialog: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    children,
    header,
    displayed,
    closeHandler,
    colorMode,
    disableStopBodyScrolling = false,
  } = props;

  useEffect(() => {
    if (!disableStopBodyScrolling) {
      stopBodyScrolling(displayed);
    }
  }, [displayed]);

  useEffect(() => {
    return () => {
      if (!disableStopBodyScrolling) {
        stopBodyScrolling(false);
      }
    };
  }, []);

  let innerStyle: any = FullScreenModalDialogStyle.inner;
  let closeStyle: any = FullScreenModalDialogStyle.close;
  const closeButtonStyle: any = FullScreenModalDialogStyle.closeButton;
  const innerContentStyle: any = FullScreenModalDialogStyle.innerContent;
  if (colorMode === "light") {
    innerStyle = FullScreenModalDialogStyle.innerLight;
    closeStyle = FullScreenModalDialogStyle.closeLight;
  }
  return (
    <ModalDialogWithClose
      displayed={displayed}
      closeHandler={closeHandler}
      styles={{
        innerStyle: innerStyle,
        innerContentStyle: innerContentStyle,
        closeButtonStyle: closeButtonStyle,
        closeStyle: closeStyle,
      }}
    >
      {header}
      {children}
    </ModalDialogWithClose>
  );
};
