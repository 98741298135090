import * as React from "react";
import { css } from "aphrodite";
import { DayStyle } from "./DayStyle";

interface IProps {
  children: string;
}

export const DayLabel: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  return (
    <span className={css(DayStyle.base, DayStyle.label)}>{props.children}</span>
  );
};
