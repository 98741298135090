import { StyleSheet } from "aphrodite";
import { borderBox } from "main/javascripts/styles/mixin/borderBoxStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";

export interface IStyle {
  block: any;
  arrowLeft: any;
  arrowRight: any;
  close: any;
  entering: any;
  entered: any;
  exiting: any;
}

const arrowSize = "0.8rem";
export const enterDuration = 360;
export const exitDelay = 100;
export const exitDuration: number = enterDuration + exitDelay;

export const CalendarStyle: IStyle = StyleSheet.create({
  block: {
    ...borderBox,
    width: "auto",
    maxWidth: "440px",
    minWidth: `min(calc(100vw - ${space.atom2x}), 300px)`,
    borderRadius: borderRadius.normal,
    backgroundColor: "white",
    margin: `${space.atom} ${space.atom}`,
    position: "absolute",
    top: "88px",
    left: 0,
    zIndex: 10,
    boxShadow: "0 6px 30px rgba(40, 40, 40, 0.1)",
    ":before": {
      content: "''",
      position: "absolute",
      top: `calc(-${arrowSize} + 1px)`,
      display: "block",
      width: 0,
      height: 0,
      borderLeft: `${arrowSize} solid transparent`,
      borderRight: `${arrowSize} solid transparent`,
      borderBottom: `${arrowSize} solid white`,
    },
  },
  arrowLeft: {
    ":before": {
      left: "5%",
    },
  },
  arrowRight: {
    left: "auto",
    right: 0,
    ":before": {
      right: "5%",
    },
  },
  close: {
    color: accentColor.primaryColor,
    fontSize: fontSize.mediumHeading,
    position: "absolute",
    top: "17px",
    right: "7.143%",
    transform: "translateX(50%)",
    cursor: "pointer",
    padding: space.atom,
  },
  entering: {
    opacity: 0,
    transform: "translateY(-16px)",
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
  },
  exiting: {
    transition: `all ${enterDuration}ms ease 100ms`,
  },
});

export const defaultStyle: any = {
  transition: `all ${enterDuration}ms ease`,
  opacity: 0,
  transform: "translateY(-16px)",
  touchAction: "manipulation",
};

export const transitionStyles: any = {
  entering: {
    opacity: 0,
    transform: "translateY(-16px)",
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
  },
  exiting: {
    transition: `all ${enterDuration}ms ease ${exitDelay}ms`,
  },
  exited: {
    display: "none",
  },
};
