import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  calendar: any;
  calendarHeader: any;
  dayNames: any;
}

export const CalendarStyle: IStyle = StyleSheet.create({
  calendar: {
    padding: `${space.atom} ${space.atom2x}`,
  },
  calendarHeader: {
    textAlign: "center",
    letterSpacing: "0.05em",
    padding: space.atom,
    fontSize: "1.1875rem",
    color: textColor.secondaryDarkColor,
    fontWeight: fontWeight.light,
    [moreThanBreakpoint("tablet")]: {
      fontSize: fontSize.mediumHeading,
      color: textColor.primaryDarkColor,
      fontWeight: fontWeight.bold,
    },
  },
  dayNames: {
    paddingTop: "0.5rem",
    marginBottom: space.atom2x,
    height: "2rem",
    borderBottom: `1px solid ${borderColor.primaryLightColor}`,
    boxSizing: "border-box",
  },
});
