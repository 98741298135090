import React, { useRef } from "react";
import { css, StyleSheet } from "aphrodite";
import Transition from "react-transition-group/Transition";
import {
  ModalDialogWithCloseStyle,
  enterDuration,
  exitDuration,
  defaultBlockStyle,
  transitionBlockStyles,
  defaultInnerStyle,
  transitionInnerStyles,
} from "./ModalDialogWithCloseStyle";
import { Icon } from "../../Icon";

export interface IProps {
  children: React.ReactNode;
  displayed: boolean;
  closeHandler(): void;
  innerStyles?: {
    width?: string;
    maxWidth?: string;
    height?: string;
    margin?: string;
  };
  innerContentStyles?: {
    marginTop?: string;
  };
  styleKey?: string;
  styles?: any;
}

export const ModalDialogWithClose: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    children,
    displayed,
    closeHandler,
    innerStyles,
    innerContentStyles,
    styleKey = "default",
    styles = {},
  } = props;
  const {
    overlayStyle,
    innerStyle,
    innerContentStyle,
    closeButtonStyle,
    closeStyle,
  } = styles;

  const nodeRef = useRef(null);

  const onClickOverlay: (e: any) => void = (e: any) => {
    if (!e.target.closest(".popupInner")) {
      closeHandler();
    }
  };

  const innerWidthStyle =
    innerStyles?.width &&
    StyleSheet.create({
      style: {
        width: innerStyles.width,
      },
    });
  const innerMaxWidthStyle =
    innerStyles?.maxWidth &&
    StyleSheet.create({
      style: {
        maxWidth: innerStyles.maxWidth,
      },
    });
  const innerHeightStyle =
    innerStyles?.height &&
    StyleSheet.create({
      style: {
        height: innerStyles.height,
      },
    });
  const innerContentMarginStyle =
    innerContentStyles?.marginTop &&
    StyleSheet.create({
      style: {
        marginTop: innerContentStyles.marginTop,
        height: `calc(100% - ${innerContentStyles.marginTop})`,
      },
    });
  const innerContentPaddingStyle =
    styleKey !== "default" && ModalDialogWithCloseStyle[styleKey];

  return (
    <Transition
      timeout={{
        enter: enterDuration,
        exit: exitDuration,
      }}
      in={displayed}
      unmountOnExit={true}
      nodeRef={nodeRef}
    >
      {(state: any): JSX.Element => (
        <div
          style={{
            ...defaultBlockStyle,
            ...transitionBlockStyles[state],
          }}
          className={css(ModalDialogWithCloseStyle.block, overlayStyle)}
          onClick={onClickOverlay}
          ref={nodeRef}
        >
          <div
            style={{
              ...defaultInnerStyle,
              ...transitionInnerStyles[state],
            }}
            className={`${css(
              ModalDialogWithCloseStyle.inner,
              innerWidthStyle?.style,
              innerMaxWidthStyle?.style,
              innerHeightStyle?.style,
              innerStyle
            )} popupInner`}
          >
            <div
              className={css(
                ModalDialogWithCloseStyle.innerContent,
                innerContentPaddingStyle,
                innerContentMarginStyle?.style,
                innerContentStyle
              )}
            >
              {children}
            </div>
            <div
              onClick={closeHandler}
              className={css(
                ModalDialogWithCloseStyle.closeButton,
                closeButtonStyle
              )}
            >
              <Icon
                styleKey="close"
                styles={[ModalDialogWithCloseStyle.closeButtonIcon, closeStyle]}
              />
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export interface IStyle {
  default: any;
  noInnerContentPadding: any;
}
