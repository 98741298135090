import * as React from "react";
import { css } from "aphrodite";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  format,
} from "date-fns";
import { DatePickerStyle } from "./DatePickerStyle";
import { DayNames } from "./DayNames";
import { Button } from "../../atoms/Button";
import { enterDuration } from "../../atoms/Modal/ModalDialogWithClose/ModalDialogWithCloseStyle";
import { Calendar } from "./Calendar";
import { DateTypes } from "main/javascripts/constants/DateTypes";

const createMonthNum = 4;

interface IState {
  startDate: Date | null;
  endDate: Date | null;
  isOutOfRange: boolean;
  currentMonthNumber: number;
  isOverwriteEndDate: boolean;
}

interface IProps {
  fromDate: Date | null;
  toDate: Date | null;
  onComplete(startDate: Date | null, endDate: Date | null): void;
  styles?: any;
  disableDate?: any;
  numberOfMonths: number;
  isSingleSelection?: boolean;
  canSelectSameDate?: boolean;
  dateType?: string;
  maxDays?: number;
  outOfRangeMessage?: string;
  startDateLabel?: string;
  endDateLabel?: string;
  t?: any;
}

class ScrollableDatePickerComponent extends React.Component<
  IProps & WithTranslation,
  IState
> {
  public currentCalendarRef: any;
  public calendarBlockRef: any;
  public calendarStyle: any;

  public constructor(props: IProps & WithTranslation) {
    super(props);
    let currentMonthNumber: number = Math.min(
      createMonthNum,
      props.numberOfMonths
    );
    if (props.fromDate) {
      const monthDifference: number =
        differenceInCalendarMonths(props.fromDate, new Date()) + 1;
      currentMonthNumber = Math.max(monthDifference, currentMonthNumber);
      if (currentMonthNumber < props.numberOfMonths) {
        currentMonthNumber = currentMonthNumber + 1;
      }
    }
    this.state = {
      startDate: props.fromDate,
      endDate: props.toDate,
      isOutOfRange: false,
      currentMonthNumber: currentMonthNumber,
      isOverwriteEndDate: props.dateType === DateTypes.toDate,
    };
    this.currentCalendarRef = React.createRef();
    this.calendarBlockRef = React.createRef();
    this.calendarStyle = {
      calendarStyle: DatePickerStyle.calendar,
    };
  }

  public componentDidMount(): void {
    setTimeout(() => {
      if (this.currentCalendarRef && this.calendarBlockRef) {
        this.calendarBlockRef.current.scrollTop =
          Number(this.calendarBlockRef.current.scrollTop) +
          Number(this.currentCalendarRef.current.offsetTop);
      }
    }, enterDuration);
    if (this.calendarBlockRef) {
      this.calendarBlockRef.current.addEventListener(
        "scroll",
        this.onScrollCalendarBlock,
        !(document as any).documentMode ? { passive: true } : false
      );
    }
  }

  public componentWillUnmount(): void {
    if (this.calendarBlockRef) {
      this.calendarBlockRef.current.removeEventListener(
        "scroll",
        this.onScrollCalendarBlock
      );
    }
  }

  private onScrollCalendarBlock: () => void = () => {
    const { numberOfMonths } = this.props;
    const deltaRatio = 1.5;
    const calendarElement: any = this.calendarBlockRef.current;
    const bottomPosition: number =
      calendarElement.scrollHeight - calendarElement.clientHeight * deltaRatio;
    if (calendarElement.scrollTop >= bottomPosition) {
      const monthNumber: number = Math.min(
        this.state.currentMonthNumber + createMonthNum,
        numberOfMonths
      );
      if (monthNumber === numberOfMonths) {
        calendarElement.removeEventListener(
          "scroll",
          this.onScrollCalendarBlock
        );
      }
      this.setState({ currentMonthNumber: monthNumber });
    }
  };

  public getDate(fromDate: any): Date {
    if (fromDate) {
      return fromDate;
    } else {
      return new Date();
    }
  }
  public resetDate: () => void = () => {
    this.setState({ startDate: null, endDate: null });
  };
  private isInvalidEndDate(startDate: Date, endDate: Date): boolean {
    if (this.props.canSelectSameDate) {
      return differenceInCalendarDays(endDate, startDate) < 0;
    }
    return differenceInCalendarDays(endDate, startDate) <= 0;
  }
  private onSelect: (date: Date) => void = (date: Date) => {
    const { maxDays, isSingleSelection } = this.props;
    const { startDate, endDate, isOverwriteEndDate } = this.state;
    if (
      isSingleSelection ||
      !startDate ||
      (endDate && !isOverwriteEndDate) ||
      this.isInvalidEndDate(startDate, date)
    ) {
      this.setState({
        startDate: date,
        endDate: null,
        isOutOfRange: false,
        isOverwriteEndDate: false,
      });
    } else {
      this.setState({ endDate: date, isOverwriteEndDate: false });
      if (maxDays && differenceInCalendarDays(date, startDate) > maxDays) {
        this.setState({ isOutOfRange: true });
      }
    }
  };
  private onComplete: (e: any) => void = (e: any) => {
    e.preventDefault();
    this.props.onComplete(this.state.startDate, this.state.endDate);
    // this.resetDate();
  };
  private renderDateInfo: () => any = () => {
    const {
      isSingleSelection,
      outOfRangeMessage,
      startDateLabel,
      endDateLabel,
      t,
    } = this.props;
    const { startDate, endDate, isOutOfRange, isOverwriteEndDate } = this.state;
    let infoText = "";
    const dateFormat = "yyyy/LL/dd";
    const startDateText: string = startDate
      ? format(startDate, dateFormat)
      : "";
    const endDateText: string = endDate ? format(endDate, dateFormat) : "";
    let infoClass: any = DatePickerStyle.dateInfo;

    if (isSingleSelection) {
      if (!startDate) {
        infoText = t("search.selectDate", {
          dateName: startDateLabel || t("label:common.departureDate"),
        });
      } else {
        infoText = startDateText;
      }
    } else {
      if (outOfRangeMessage && isOutOfRange) {
        infoText = outOfRangeMessage;
        infoClass = DatePickerStyle.dateInfoAlert;
      } else if (!startDate) {
        infoText = t("search.selectDate", {
          dateName: startDateLabel || t("label:common.departureDate"),
        });
      } else {
        if (!endDate || isOverwriteEndDate) {
          infoText = t("search.selectDate", {
            dateName: endDateLabel || t("label:common.returnDate"),
          });
        } else {
          infoText = `${startDateText} - ${endDateText}`;
        }
      }
    }
    return <div className={css(infoClass)}>{infoText}</div>;
  };

  public render(): JSX.Element | null {
    const { styles, disableDate, isSingleSelection, t } = this.props;
    const date: Date = new Date();
    const startDateYearMonth: string = format(
      this.state.startDate || date,
      "yyyy/LL"
    );
    const buttonDisabled: boolean =
      !this.state.startDate ||
      (!isSingleSelection && !this.state.endDate) ||
      this.state.isOutOfRange;
    return (
      <div className={`${css(DatePickerStyle.block, styles)}`}>
        <div className={css(DatePickerStyle.dayNames)}>
          <DayNames date={date} />
        </div>
        <div
          className={css(DatePickerStyle.calendarBlock)}
          ref={this.calendarBlockRef}
        >
          {
            // tslint:disable-next-line:prefer-array-literal
            Array.from(Array(this.state.currentMonthNumber).keys()).map(
              (index: number) => {
                const calendarDate: Date = addMonths(date, index);
                const calendarDateYearMonth: string = format(
                  calendarDate,
                  "yyyy/LL"
                );
                const setRef: any =
                  startDateYearMonth === calendarDateYearMonth
                    ? this.currentCalendarRef
                    : null;
                return (
                  <Calendar
                    key={index}
                    date={calendarDate}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onSelect={this.onSelect}
                    disableDate={disableDate}
                    showDayNames={false}
                    ref={setRef}
                    styles={this.calendarStyle}
                  />
                );
              }
            )
          }
        </div>
        <div className={css(DatePickerStyle.footer)}>
          {this.renderDateInfo()}
          <Button
            type="button"
            disabled={buttonDisabled}
            onClick={this.onComplete}
            styles={{ button: DatePickerStyle.button }}
          >
            {t("search.done")}
          </Button>
        </div>
      </div>
    );
  }
}
export const ScrollableDatePicker: any = withTranslation(["component"])(
  ScrollableDatePickerComponent
);
