import * as React from "react";
import {
  isSameDay,
  isBefore,
  isSameMonth,
  isWithinInterval,
  differenceInCalendarDays,
  subDays,
} from "date-fns";
import { DayStyle } from "./DayStyle";
import { css } from "aphrodite";

interface IProps {
  date: Date;
  startDate: Date | null;
  endDate: Date | null;
  currentDate: Date;
  onClick(date: Date): void;
  onMouseOver?(date: Date): void;
  onMouseOut?(): void;
  disableDate?: any;
}

export class Day extends React.PureComponent<IProps> {
  private onClick: () => void = () => {
    const today: Date = new Date();
    if (this.isDisabled(this.props.disableDate || today)) {
      return;
    }
    this.props.onClick(this.props.date);
  };
  private onMouseOver: () => void = () => {
    const today: Date = new Date();
    if (
      !this.props.onMouseOver ||
      this.isDisabled(this.props.disableDate || today)
    ) {
      return;
    }
    this.props.onMouseOver(this.props.date);
  };
  private onMouseOut: () => void = () => {
    if (this.props.onMouseOut) {
      this.props.onMouseOut();
    }
  };
  private isDisabled(today: Date): boolean {
    if (
      !isSameMonth(this.props.date, this.props.currentDate) ||
      isBefore(this.props.date, subDays(today, 1))
    ) {
      return true;
    }
    // amadeusの仕様で361日以降は検索できない
    if (differenceInCalendarDays(this.props.date, today) > 360) {
      return true;
    }
    return false;
  }
  public render(): JSX.Element {
    const { date, startDate, endDate, disableDate } = this.props;
    const today: Date = new Date();
    const classNames: string[] = [DayStyle.base];

    if (isSameDay(today, date)) {
      classNames.push(DayStyle.active);
    }

    if (
      startDate !== null &&
      endDate !== null &&
      isBefore(startDate, endDate) &&
      isWithinInterval(date, { start: startDate, end: endDate })
    ) {
      classNames.push(DayStyle.between);
    }

    if (startDate !== null && isSameDay(date, startDate)) {
      classNames.push(DayStyle.start);
    }

    if (endDate !== null && isSameDay(date, endDate)) {
      classNames.push(DayStyle.end);
    }

    if (this.isDisabled(disableDate || today)) {
      if (startDate !== null && isSameDay(date, startDate)) {
        classNames.push(DayStyle.startMuted);
      } else {
        classNames.push(DayStyle.muted);
      }
    }

    return (
      <span
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        className={css(classNames)}
      >
        {date.getDate()}
      </span>
    );
  }
}
