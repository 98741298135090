import * as React from "react";
import { css } from "aphrodite";
import { ButtonStyle, IStyle } from "./ButtonStyle";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  styles?: any;
  styleKey?: keyof IStyle;
}

const ButtonComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles = {}, children, styleKey = "button", ...rest } = props;
  const { block, button } = styles;
  return (
    <div className={css(ButtonStyle.block, block)}>
      <button className={css(ButtonStyle[styleKey], button)} {...rest}>
        {children}
      </button>
    </div>
  );
};
export const Button: any = React.memo(ButtonComponent);
