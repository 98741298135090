import { StyleSheet } from "aphrodite";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  base: any;
  active: any;
  muted: any;
  startMuted: any;
  start: any;
  end: any;
  between: any;
  label: any;
  daysBlock: any;
}

export const DayStyle: IStyle = StyleSheet.create({
  base: {
    width: "14.28571%",
    display: "inline-block",
    textAlign: "center",
    userSelect: "none",
    cursor: "pointer",
    lineHeight: "3rem",
    position: "relative",
    fontSize: fontSize.caption,
    fontWeight: fontWeight.bold,
    "-webkitTapHighlightColor": "rgba(0,0,0,0)",
    [moreThanBreakpoint("tablet")]: {
      lineHeight: "2.5rem",
      ":hover": {
        backgroundColor: "#ddd",
      },
    },
  },
  active: {
    fontWeight: fontWeight.bold,
    color: accentColor.primaryColor,
  },
  muted: {
    fontWeight: fontWeight.regular,
    color: "rgba(0, 0, 0, 0.33)",
    cursor: "initial",
    ":hover": {
      background: "none",
    },
  },
  between: {
    position: "relative",
    zIndex: "auto",
    "::before": {
      content: "''",
      display: "block",
      position: "absolute",
      zIndex: -1,
      backgroundColor: "#eee",
      width: "50.1%",
      height: "100%",
      top: "0",
      right: "50%",
    },
    "::after": {
      content: "''",
      display: "block",
      position: "absolute",
      zIndex: -1,
      backgroundColor: "#eee",
      width: "50.1%",
      height: "100%",
      top: "0",
      left: "50%",
    },
  },
  startMuted: {
    backgroundColor: accentColor.primaryColor,
    color: "#fff",
    cursor: "initial",
    "::before": {
      display: "none",
    },
    ":hover": {
      backgroundColor: accentColor.primaryColor,
    },
  },
  start: {
    backgroundColor: accentColor.primaryColor,
    color: "#fff",
    "::before": {
      display: "none",
    },
    ":hover": {
      backgroundColor: accentColor.primaryColor,
    },
  },
  end: {
    backgroundColor: accentColor.primaryColor,
    color: "#fff",
    "::after": {
      display: "none",
    },
    ":hover": {
      backgroundColor: accentColor.primaryColor,
    },
  },
  label: {
    textTransform: "uppercase",
    fontWeight: fontWeight.regular,
    color: "rgba(0, 0, 0, 0.33)",
    fontSize: fontSize.caption,
    cursor: "initial",
    padding: 0,
    lineHeight: 1,
    paddingBottom: space.atom0_5x,
    [moreThanBreakpoint("tablet")]: {
      lineHeight: 1,
      ":hover": {
        background: "none",
      },
    },
  },
  daysBlock: {
    position: "relative",
    zIndex: 1,
  },
});
